
export const judgeIsSystemTag = (tagData) => {
    return tagData.teamId === '1'
}
export function matchString(text,keyword){
    if(typeof keyword !== 'string' || keyword.length === 0) {
        return {
            matched:false
        };
    };

    const siftedInfos = [];
    const keywordChars = keyword.split('');
    const textChars = text.split('');
    let matched = true;
    let charIndex = 0;
    let currentExtractedTextChar  = ''

    function mergeRearSiftedInfo(matched){
        const front = siftedInfos.pop();
        const rear = {content: currentExtractedTextChar,index:charIndex,matched}
        const infos = [rear];
        if(front){
            if(front.index + front.content.length === charIndex && front.matched === matched){
                rear.content = front.content + currentExtractedTextChar;
                rear.index = front.index;
            }else{
                infos.unshift(front);
            }
        }
        siftedInfos.push(...infos);
    }

    while(keywordChars.length && matched){
        currentExtractedTextChar = textChars.shift();
        while(keywordChars[0] !== currentExtractedTextChar && currentExtractedTextChar){
            mergeRearSiftedInfo(false);
            currentExtractedTextChar = textChars.shift();
            charIndex++;
        }

        if(!currentExtractedTextChar) {
            matched = false;
            break;
        }
        mergeRearSiftedInfo(true);
        keywordChars.shift();
        charIndex++;
    }

    if(textChars.length){
        const lastInfo = siftedInfos[siftedInfos.length - 1];
        siftedInfos.push({
            content:textChars.join(''),
            index: lastInfo ? (lastInfo.index + lastInfo.content.length) : 0,
            matched:false
        })
    }

    return {
        matched,
        siftedInfos,
        allMatched: matched && textChars.length === 0 && siftedInfos.length === 1
    }
}
