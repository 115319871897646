export const TagType = {
    NORMAL:0,
    NEED_RESOLVE:1
}

export const TagListItemSettingMenuMode = {
    UNIVERSAL:'universal',
    ONLY_NEED_RESOLVE:'onlyNeedResolve'
}

export const TagCompositionMode = {
    DISPLAY:0,
    INTACT_EDIT:1
}

export const TagStatus = {
    pending:0,
    complete:1,
}

export const TagStyleStatic = {
    containerPadding:9,
    closeBtnWidth:17,
    tagContentBoxHeight:24,
}

export const eventsName = {
    TAG_LIST_OPENED:'tagListOpened',
    TAG_DATA_SETTING_CHANGED:'tagDataSettingChanged',
    CHANGE_PREVENT_STATE:'CHANGE_PREVENT_STATE',
    DELETED_FROM_SETTING:'DELETED_FROM_SETTING',
    RESOLVE_TAG:'resolveTag',
    PENDING_TAG: 'pendingTag'
}
