<template>
  <div class="certificate-tag" ref="selfRef">
    <MetaTag
        :tag-data="tagData"
        :has-cursor-tip="!preventModifyResolution && needResolve"
        style="box-sizing:border-box;max-width: 100%;"
        :type="needResolve ? 'primary' : 'default'"
        @click="handleTagClicked"
        @closeBtnClicked="handleCloseBtnClicked"
        :show-close-btn="showCloseBtn"
        :keyword="keyword"
        :truncate="truncate"
        :close-btn-box-width="TagStyleStatic.closeBtnWidth"
        ref="tag"
    >
      <template #icon v-if="needResolve">
        <svg-icon v-if="state.status != TagStatus.complete && tagData.voucherRequired" name='upload' width="14" height="14"/>
        <svg-icon width="14" height="14" name="okay" v-else-if="state.status == TagStatus.complete"/>
      </template>
    </MetaTag>
    <el-dialog
        v-model="visibilities.confirmStatusDialogVisible"
        :width="500"
        custom-class="ct-dialog"
        :append-to-body="true"
        title="确认完成"
    >
      <div class="ct-confirm-tip-content">
        <svg-icon class="confirm-tip-icon" name="notion" width="20" height="20"></svg-icon>
        您要将此事件标记为已完成吗？确认后，您对此事件的标记将被留痕<br/>
        如有相关凭证，<em @click="handleConvertUploadCertificate">点此上传（可选项）</em>
      </div>
      <template #footer>
        <el-button type="default" @click="handleResolveCancel()">取消</el-button>
        <el-button type="primary" @click="handleResolveConfirm()">确定</el-button>
      </template>
    </el-dialog>
    <el-dialog
        v-model="visibilities.uploadDialogVisible"
        :width="500"
        custom-class="ct-dialog"
        :append-to-body="true"
        title="上传凭证"
    >
      <el-upload
          drag
          class="ct-upload"
          :on-change="handleUploadFileChanged"
          :auto-upload="false"
          :show-file-list="false"
          :multiple="false"
          action="empty"
      >
        <i class="el-icon-upload" />
        <div class="tc-upload-text">
          将文件拖到此处，或<em>点击上传</em>
        </div>
      </el-upload>
    </el-dialog>

    <el-dialog
        v-model="visibilities.confirmDeleteStatusTagDialogVisible"
        :width="500"
        :append-to-body="true"
        custom-class="ct-dialog"
        title="确认删除"
    >
      <div class="ct-confirm-tip-content">
        <svg-icon class="confirm-tip-icon" name="notion" width="20" height="20"></svg-icon>
        您要删除此待办吗？确认删除后，此待办标签将解除已完成状态
      </div>

      <template #footer>
        <el-button type="default" @click="handleResolveCancel(true)">取消</el-button>
        <el-button type="primary" @click="handleResolveConfirm(true)">确定</el-button>
      </template>
    </el-dialog>

    <el-dialog
        v-model="visibilities.confirmCancelCompleteStatusDialogVisible"
        :width="500"
        :append-to-body="true"
        custom-class="ct-dialog"
        title="确认取消"
    >
      <div class="ct-confirm-tip-content">
        <svg-icon class="confirm-tip-icon" name="notion" width="20" height="20"></svg-icon>
        您要取消此待办吗？确认取消后，此待办标签将转为未完成状态，您可以重新上传凭证
      </div>

      <template #footer>
        <el-button type="default" @click="handleCancelResolveCancel()">取消</el-button>
        <el-button type="primary" @click="handleCancelResolveConfirm()">确定</el-button>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import MetaTag from "./MetaTag";
import {ElMessage} from 'element-plus';
import {ref, reactive, watch, toRef, toRefs, computed, inject, onBeforeUpdate} from 'vue';
import {eventsName, TagStatus, TagStyleStatic, TagType} from "./configure";
import SvgIcon from "../SvgIcon/svgIcon";
import {colorLog} from "../../util";
import {judgeIsSystemTag} from "./util";
export default {
  name: "CertificateTag",
  props:{
    tagData:{
      required:false,
      default:{
        name:'',
        voucherRequired:false,
        status:TagStatus.pending,
      }
    },
    preventModifyResolution:{
      required:false,
      default:false
    },
    showCloseBtn:{
      required:false,
      default:false,
    },
    keyword:{
      required:false,
      default:''
    },
    truncate:{
      required:false,
      default:true,
    },
    justForShow:{
      required:false,
      default:false
    },
    externalRequests:{
      //如果单独用此组件,其实是需要传该prop
      required:false,
      default: null
      // {
      //   completeTag:null, //完成和目标实体关联的待办tag
      //   cancelTagCompleteStatus:null //取消完成和目标实体关联的待办tag
      // }
    }
  },
  components: {SvgIcon, MetaTag},
  setup(props,ctx){
    const selfRef = ref(null);
    let externalRequests;
    let parentEmitter;
    if(!props.justForShow){
      externalRequests = props.externalRequests ? props.externalRequests : inject('externalRequests');
      parentEmitter = inject('emitter');
    }


    const propsRefs = toRefs(props);
    const tag = ref(null);
    const visibilities = reactive({
      uploadDialogVisible:false,
      confirmStatusDialogVisible:false,
      confirmDeleteStatusTagDialogVisible:false,
      confirmCancelCompleteStatusDialogVisible:false
    })
    const isSystemTag = computed(() => judgeIsSystemTag(props.tagData));

    const state = reactive({
      status:TagStatus.pending
    });
    watch(toRef(props.tagData,"status"),(status)=>{
      // colorLog.blue(`${props.tagData.name} status: {props.tagData.status}`,status);
      state.status = status;
    },{
      immediate:true
    });

    watch(toRef(visibilities,'confirmDeleteStatusTagDialogVisible'),(newValue) => {
      ctx.emit('preventChangeMode',newValue);
    });

    const needResolve = computed(() => {
      return props.tagData.type === TagType.NEED_RESOLVE;
    })

    const handleTagClicked = () => {
      if(props.preventModifyResolution || props.justForShow || !needResolve.value) return;
      if(state.status === TagStatus.complete){
        //打开确认取消完成的窗口
        visibilities.confirmCancelCompleteStatusDialogVisible = true;
        return;
      }
      if(props.tagData.voucherRequired){
        visibilities.uploadDialogVisible = true;
      }else{
        visibilities.confirmStatusDialogVisible = true;
      }
    }
    const handleUploadFileChanged = async (file, fileList) => {
      ElMessage.info('上传凭证中,请稍等');
      try {

        console.log(file)
        let formData = new FormData();
        formData.append("file", file.raw);
        await externalRequests?.completeTag(props.tagData.id,formData);
        ElMessage.success('上传凭证成功');


        ctx.emit('tagResolved',props.tagData);
        propsRefs.tagData.value.status = state.status = TagStatus.complete;
        parentEmitter?.emit(eventsName.RESOLVE_TAG,props.tagData);
        visibilities.uploadDialogVisible = false;

      }catch (e){
        ElMessage.error('上传凭证失败');
        console.error(e);
      }

    }
    const handleResolveConfirm = async (isDelete) => {
      if(isDelete){
        visibilities.confirmDeleteStatusTagDialogVisible = false;
        ctx.emit('closeBtnClicked',props.tagData);
      }else{
        visibilities.confirmStatusDialogVisible = false;
        const formData = new FormData();
        formData.append('file',new File([],'empty'));
        await externalRequests?.completeTag(props.tagData.id,formData);
        propsRefs.tagData.value.status = state.status = TagStatus.complete;
        ctx.emit('tagResolved',props.tagData);
        parentEmitter?.emit(eventsName.RESOLVE_TAG,props.tagData);
      }
    }

    const handleResolveCancel = (isDelete) => {
      if(isDelete){
        visibilities.confirmDeleteStatusTagDialogVisible = false;
      }else{
        visibilities.confirmStatusDialogVisible = false;
      }
    }

    const handleCloseBtnClicked = () => {
      if(state.status === TagStatus.complete){
        visibilities.confirmDeleteStatusTagDialogVisible = true;
      }else{
        ctx.emit('closeBtnClicked',props.tagData);
      }
    }

    const handleConvertUploadCertificate = () => {
      visibilities.confirmStatusDialogVisible = false;
      visibilities.uploadDialogVisible = true;
    }

    //取消待办完成状态相关
    //
    const handleCancelResolveCancel = () => {
      visibilities.confirmCancelCompleteStatusDialogVisible = false;
    }
    const handleCancelResolveConfirm = async () => {
      visibilities.confirmCancelCompleteStatusDialogVisible = false;
      await externalRequests?.cancelTagCompleteStatus(props.tagData.id);
      state.status = TagStatus.pending;
      parentEmitter?.emit(eventsName.PENDING_TAG,props.tagData);
    }
    //
    //end//

    const api = {
      getTagIntactMarginBoxSize: () => {
        const hasIcon = props.tagData.type == TagType.NEED_RESOLVE && (props.tagData.voucherRequired || props.tagData.status == TagStatus.complete);
        // colorLog.green(`${props.tagData.name}`,props.tagData,hasIcon,tag.value.api.getTagIntactMarginBoxSize(hasIcon))
        return tag.value.api.getTagIntactMarginBoxSize(hasIcon);
      },
      openResolveDialog: () => handleTagClicked(),

    };

    return {
      TagType,
      TagStyleStatic,
      TagStatus,
      selfRef,
      needResolve,
      tag,
      state,
      visibilities,
      isSystemTag,
      handleTagClicked,
      handleResolveConfirm,
      handleResolveCancel,
      handleConvertUploadCertificate,
      handleCloseBtnClicked,
      handleUploadFileChanged,
      handleCancelResolveConfirm,
      handleCancelResolveCancel,
      api
    }
  }
}
</script>
<style>
.ct-dialog{
  text-align: center;
}
</style>
<style scoped>
.certificate-tag{
  font-size: 0;
  line-height: normal;
}
em{
  color:var(--lightBlue);
  font-style: normal;
  cursor:pointer;
}
.ct-confirm-tip-content{
  padding: 0 10px;
}
.confirm-tip-icon{
  position: absolute;
  margin-left: -35px;
}
.ct-confirm-tip-content{
  text-align: left;
  padding-left: 35px;
}

</style>
