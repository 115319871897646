<template>
  <div class="meta-tag"
       :class="{
        default: type === 'default',
        primary: type === 'primary',
        hasCursorTip,
        'show-close-btn':showCloseBtn,
     }"
       :style="{
        '--close-btn-size':closeBtnTotalWidth - tagStyle.closeBtnMarginLeft + 'px',
        '--tag-padding-left':tagStyle.paddingLeft + 'px',
        '--tag-padding-right':tagStyle.paddingRight + 'px',
        '--text-line-height':tagStyle.lineHeight,
        '--text-margin-left': tagStyle.textMarginLeft + 'px',
        '--text-font-size': tagStyle.textFontSize + 'px',
        '--close-btn-margin-left': tagStyle.closeBtnMarginLeft + 'px',
       }"
       ref="selfRef">
    <span class="tag-icon" ref="iconRef">
          <slot name="icon"></slot>
    </span>
    <span class="sifted-tag" v-if="keywordAllMatched" ref="textAreaRef">
    <span class="sifted-fragment"
          :class="{matched:item.matched}"
          v-for="item in siftedTextInfo">
      {{item.content}}
    </span>
  </span>
    <span v-else ref="textAreaRef"
          :title="tagData.name"
          class="tag-text truncate">{{tagLabel}}</span>
    <div class="mt-close-btn"
         ref="closeBtn"
         :class="{
         show:showCloseBtn
       }"
        @click="$emit('closeBtnClicked',tagData)"
    >
      <svg-icon name="close" width="8" height="8"></svg-icon>
    </div>
  </div>
</template>

<script>
import {ref, computed, watch, toRef, toRefs, reactive} from 'vue';
import {colorLog, deepClone} from "../../util";
import {matchString} from "./util";

export default {
  name: "MetaTag",
  props:{
    tagData:{
      required:true,
      //id,text
    },
    keyword:{
      required: false,
      default:''
    },
    showCloseBtn:{
      required:false,
      default:false
    },
    type:{
      required:false,
      default:'default'
    },
    hasCursorTip:{
      required:false,
      default: false
    },
    closeBtnBoxWidth:{
      required:false,
    },
    truncate:{
      required:false,
      default:true
    }
  },
  setup(props){
    const propsRefs = toRefs(props);
    const iconRef = ref(null);
    const textAreaRef = ref(null);
    const tagStyle = reactive({
      paddingRight:8,
      paddingLeft:8,
      textMarginLeft:3,
      textFontSize:12,
      closeBtnMarginLeft:5,
      lineHeight:2,
    });

    const selfRef = ref(null);
    const closeBtn = ref(null);
    const closeBtnTotalWidth = ref(17);
    watch(propsRefs.closeBtnBoxWidth,(width) => {
      if(!width) return;
      closeBtnTotalWidth.value = width;
    },{
      immediate:true
    })
    const tagLabel = computed(() => {
      if(props.truncate){
        return props.tagData.name.length > 6 ? props.tagData.name.slice(0,6) + '...' : props.tagData.name
      }
      return props.tagData.name;
    })

    const keywordAllMatched = ref(false);
    const siftedTextInfo = ref([]);
    const isMatchedMultiFragments = computed(() => {
      return siftedTextInfo.value.filter(info => info.matched).length > 1;
    });
    const matchedCharCount = computed(() => {
      return siftedTextInfo.value.reduce((num,curr)=> curr.matched ? (num + curr.content.length) : num ,0)
    });

    watch([propsRefs.tagData,propsRefs.keyword],([newTagData,newKeyword]) => {
      const matchedInfo = matchString(props.tagData.name,newKeyword);
      keywordAllMatched.value = matchedInfo.matched;

      if(matchedInfo.matched){
        siftedTextInfo.value = deepClone(matchedInfo.siftedInfos);
      }
    },{immediate:true,deep:true});


    const api = {
      getTagIntactMarginBoxSize: (hasIcon) => {
        return {
          width: closeBtnTotalWidth.value + tagStyle.paddingLeft + tagStyle.paddingRight + (hasIcon ? (iconRef.value.clientWidth + tagStyle.textMarginLeft) : 0) + textAreaRef.value.clientWidth,
          height: selfRef.value.clientHeight
        }

      },
    }

    return {
      tagStyle,
      selfRef,
      textAreaRef,
      tagLabel,
      iconRef,
      closeBtn,
      closeBtnTotalWidth,
      siftedTextInfo,
      keywordAllMatched,
      isMatchedMultiFragments,
      matchedCharCount,

      api
    }
  }
}
</script>

<style scoped>
.meta-tag{
  --close-btn-size:12px;
  --tag-padding-left:8px;
  --tag-padding-right:8px;
  --text-line-height:2;
  --text-margin-left:3px;
  --text-font-size:12px;
  --close-btn-margin-left:5px;

  position: relative;
  display: inline-flex;
  align-items: center;
  padding: 0px var(--tag-padding-left) 0 var(--tag-padding-right);
  border-radius:4px;
  line-height: var(--text-line-height);
  font-size: var(--text-font-size);
  transition: padding .2s var(--cubic-bounce-1);
  overflow:hidden;
}
.meta-tag.show-close-btn{
  padding-right: calc(var(--tag-margin-right) + var(--close-btn-margin-left) + var(--close-btn-size));
}

.meta-tag.default{
  background: var(--gray-4);
}
.meta-tag.primary{
  background: var(--blue-1);
}
.meta-tag.hasCursorTip{
  cursor:pointer;
}
.tag-icon{
  display:inline-block;
  color:var(--lightBlue);
}
.mt-close-btn{
  width:var(--close-btn-size);
  height:var(--close-btn-size);

  right: var(--tag-margin-right);
  position: absolute;
  margin-left: var(--close-btn-margin-left);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transform: scale(0);
}

.mt-close-btn.show{
  transition: all .2s ease-out;
  transform: scale(1);
}

.sifted-fragment,
.tag-text{
  user-select: none;
  font-weight: bold;
  word-break: keep-all;
  white-space: nowrap;
  flex-shrink: 1;
  min-width: 0;
}
.mt-close-btn:hover{
  color:var(--lightBlue);
}
.sifted-fragment.matched{
 background: rgb(198 219 200);
}
.tag-icon >>> .svg-icon{
  vertical-align: -.2em;
}
.tag-icon:not(:empty) + .sifted-tag,
.tag-icon:not(:empty) + .tag-text
{
  margin-left: var(--text-margin-left);
}
</style>
